<template>
  <div>
    <div class="safe-area">
      <el-page-header v-if="historyLength>1" class="margin-top-20" @back="$router.go(-1)" content="商品详情"></el-page-header>
      <div class="column flex-row margin-top-20" v-loading="prodDet.loading">
        <div style="width: 500px; flex:0 0 500px">
          <div class="swiper-container prod-swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in prodDet.data.img_info?prodDet.data.img_info.title:[]" :key="index"><el-image class="pic" :src="item" fit="contain"></el-image></div>
            </div>
          </div>
          <div class="swiper-container thumbs-swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in prodDet.data.img_info?prodDet.data.img_info.title:[]" :key="index"><el-image class="pic" :src="item" fit="contain"></el-image></div>
            </div>
            <div class="swiper-button-prev font-size-20 text-align-center" style="background: none; line-height: 44px;"><i class="el-icon-arrow-left"></i></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <div class="swiper-button-next font-size-20 text-align-center" style="background: none; line-height: 44px;"><i class="el-icon-arrow-right"></i></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
          </div>
        </div>
        <div class="flex-auto margin-left-20">
          <div class="font-size-20 bold">{{prodDet.data.spmch}}</div>
          <div class="detail-price margin-top-10">
            <div class="flex-row align-items-center">
              <div>超值低价</div>
              <el-tag size="mini" type="warning" effect="dark" class="margin-left-10">{{activeSpec.sale_info}}</el-tag>
              <el-tag size="mini" type="warning" effect="dark" class="margin-left-10">库存：{{activeSpec.pcjcshl}}</el-tag>
            </div>
            <div class="flex-row align-items-center margin-top-10">
              <div>￥<span class="color-orange font-size-24 bold">{{spPrice | numFormat(2)}}</span></div>
              <div style="text-decoration: line-through;" class="margin-left-10">{{activeSpec.ori_price}}</div>
            </div>
            <div class="flex-row margin-top-10 padding-top-10 border-top"  v-if="q.hd_id&&activeSpec.hd_info">
              <div>{{ activeSpec.hd_info.memo }}</div>
              <div class="margin-left-20 text-nowrap color-red bold">{{countdown.text}}</div>
            </div>
          </div>
          
          <div class="flex-row justify-between line-height-medium margin-tb-10 border-bottom padding-bottom-10">
            <div>
              <div v-for="(item,index) in baseInfo(prodDet.data.base_info_left)" :key="index">
                <span class="color-sub">{{item.key}}：</span>{{item.value}}
              </div>
            </div>
            <div>
              <div v-for="(item,index) in baseInfo(prodDet.data.base_info_right)" :key="index">
                <span class="color-sub">{{item.key}}：</span>{{item.value}}
              </div>
            </div>
          </div>
           <div class="flex-row justify-between line-height-medium margin-tb-10 border-bottom padding-bottom-10">
            <div>
              
                <span class="color-sub">生产日期：</span>{{activeSpec.scrq}}

            </div>
            <div>
              
                <span class="color-sub">有效期至：</span>{{activeSpec.yxq}}

            </div>
          </div>
          <div class="flex-row align-items-center">
            <span class="color-sub text-nowrap">批号：</span>
            <div class="specifications">
              <div v-for="(item,index) in prodDet.price_info" :key="index" @click="changeSpec(index)" :class="{'active':activeSpecIndex==index}">{{item.pihao}}</div>
            </div>
          </div>
          <div class="flex-row align-items-center margin-top-20" v-if="$store.getters.isSpecUser">
            客户：<div class="flex-auto"><CustomerList :listener="false"></CustomerList></div>
          </div>
          <div class="flex-row text-nowrap margin-top-20">
            <div class="flex-row align-items-center margin-right-20" v-if="activeSpec.low_price!=activeSpec.high_price">
              修改价格(￥{{activeSpec.low_price}}-{{activeSpec.high_price}})：￥
              <el-input v-model="customPrice" @blur="checkCustomPrice" style="width: 80px; margin: 0 3px;"></el-input> 
            </div>
            <div class="flex-row align-items-center">
              数量：
              <el-button @click="changeNum('reduce')">-</el-button> 
              <el-input class="text-align-center" v-model="prodNum" @blur="checkProdNum" style="width: 70px; margin: 0 3px;"></el-input> 
              <el-button @click="changeNum('add')">+</el-button> 
            </div>
          </div>
          <div class="flex-row margin-top-20">
            <el-button type="primary" round :disabled="!activeSpec.pcjcshl||prodNum<=0" @click="submit">购买</el-button>
            <el-button type="success" round icon="el-icon-shopping-cart-2" :disabled="!activeSpec.pcjcshl||prodNum<=0" :loading="cartLoading" @click="addCart">加入购物车</el-button>
            <template v-if="!q.hd_id">
              <el-tooltip content="取消收藏" placement="top" v-if="prodDet.data.collect_state">
                <el-button type="primary" icon="el-icon-star-on" circle style="margin-left:10px" :loading="collectLoading" @click="cancelCollect"></el-button>
              </el-tooltip>
              <el-tooltip content="收藏商品" placement="top" v-else>
                <el-button type="" icon="el-icon-star-off" circle style="margin-left:10px" :loading="collectLoading" @click="saveCollect"></el-button>
              </el-tooltip>
            </template>
          </div>
        </div>
      </div>
      <div class="flex-row">
        <div class="column" style="flex:0 0 220px;padding:20px 10px">
          <div class="flex-row align-items-center justify-between margin-bottom-15">
            <span class="font-size-20 bold margin-left-10">最近浏览</span>
            <div class="link"  @click="$root.jump({path:'/user/browsingHistory'})">查看更多 <i class="el-icon-caret-right"></i></div>
          </div>
          <div class="product-list-item" v-for="(item,index) in historyList" :key="index" @click="$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:item.spid,hd_id:item.hd_id})}})">
            <div class="pic">
              <el-image class="img flex-column"  style="height:200px" :src="item.img_url" fit="contain">
                <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                  <img src="@/assets/images/no-pic.png" height="200px"/>
                </div>
              </el-image>
              <div class="gwc"><el-button @click.stop="addShoppingCart(item)" type="primary" plain :disabled="$store.state.loginRes.audit_state!=2" circle icon="el-icon-shopping-cart-full"></el-button></div>
            </div>
            <div class="padding-15 flex-auto flex-column">
              <div class="flex-auto">
                <div class="font-size-16 bold">{{item.spmch}}</div>
                <div class="color-sub margin-top-5">{{item.shengccj}}</div>
                <div class="color-sub margin-top-5">{{item.shpgg}}</div>
              </div>
              <div class="flex-row align-items-center justify-between margin-top-5">
                <div class="color-orange">￥<span class="font-size-18 bold ">{{item.hshsj | numFormat(2)}}</span></div>
                <div class="color-sub">库存：{{item.pcjcshl_str}}</div>
              </div>
            </div>
          </div>
          <div class="link text-align-center"  @click="$root.jump({path:'/user/browsingHistory'})">查看更多 <i class="el-icon-caret-right"></i></div>
        </div>
        <div class="column flex-auto margin-left-20" ref="main">
          <el-tabs v-model="detailTabActive">
            <el-tab-pane label="商品详情" name="det"></el-tab-pane>
            <el-tab-pane label="客户评价" name="eval"></el-tab-pane>
          </el-tabs>
          <div class="details" v-if="detailTabActive=='det'">
            <img v-for="(item,index) in prodDet.data.img_info?prodDet.data.img_info.content:[]" :key="index" :src="item" />
          </div>
          <div v-if="detailTabActive=='eval'">
            <!-- <div class="flex-row align-items-center margin-bottom-20">
              <div class="color-sub">好评度</div>
              <div class="color-orange margin-left-10"><span class="font-size-24 bold">99.7</span>%</div>
            </div> -->
            <div class="flex-row padding-tb-10 border-bottom" v-for="(item,i) in listEvalInfo.list" :key="i">
              <div><el-avatar :size="50" icon="el-icon-user-solid" :src="avatar"></el-avatar></div>
              <div class="flex-auto margin-left-20">
                <div class="flex-row">
                  <div>{{item.user_name}}</div>
                  <div class="margin-left-10"><i v-for="(item,i) in 5" :key="i" class="el-icon-star-on color-orange"></i></div>
                </div>
                <div class="margin-top-5">{{item.pj_msg}}</div>
                <div class="flex-row flex-wrap">
                  <div class="margin-5 link" @click="activedImgOfEvalShow=true;activedImgOfEval=item_img" v-for="(item_img,i_img) in item.img_list" :key="i_img"><el-image class="border" :src="item_img" fit="contain" style="width: 80px; height: 80px;"></el-image></div>
                </div>
                <div class="text-align-right margin-top-5 color-sub font-size-12">{{item.create_time}}</div>
              </div>
            </div>
            <div v-if="listEvalInfo.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
            <div v-if="listEvalInfo.noMore&&listEvalInfo.list.length" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
            <el-empty v-if="!listEvalInfo.list.length&&listEvalInfo.page==1&&!listEvalInfo.loading" description="暂无数据"></el-empty>
          </div>
        </div>
      </div>

      <!-- <div class="flex-row align-items-center margin-tb-20">
        <el-image style="width: 24px; height: 24px" :src="columnIcon" fit="contain"></el-image>
        <span class="font-size-20 bold margin-left-10">同类产品推荐</span>
      </div>
      <el-row :gutter="20" type="flex">
        <el-col :span="6" v-for="(item,index) in 4" :key="index">
          <div class="product-list-item">
            <div class="pic">
              <el-image class="pic" src="https://c1.yaofangwang.net/22/3727/5a5d457bdb76d6ac359cdf7ca7d00e4f.png_300x300.jpg" fit="contain"></el-image>
              <div class="gwc"><el-button type="primary" plain circle icon="el-icon-shopping-cart-full"></el-button></div>
            </div>
            <div class="padding-15 flex-auto flex-column">
              <div class="flex-auto">
                <div class="font-size-16 bold">复方倍氯米松樟脑乳膏</div>
                <div class="color-sub margin-top-5">江中药业股份有限公司</div>
                <div class="color-sub margin-top-5">0.8g*8片*4板</div>
              </div>
              <div class="flex-row align-items-center justify-between margin-top-5">
                <div class="color-orange">￥<span class="font-size-20 bold ">登录可见</span></div>
                <div class="color-sub">库存：200</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row> -->
    </div>
    <el-dialog custom-class="custom-dialog" title="加入购物车"  :visible.sync="addShoppingCartShow" :append-to-body="true" width="1100px" top="15vh">
      <div>
        <AddShoppingCart :q="gwcQuery"></AddShoppingCart>
      </div>
    </el-dialog>
    <el-dialog custom-class="custom-dialog" title=""  :visible.sync="activedImgOfEvalShow" :append-to-body="true" width="1100px" top="15vh">
      <div>
        <el-image class="border" :src="activedImgOfEval" fit="contain" style="width: 100%; height: calc(100vh - 100px - 30vh);"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css';
import columnIcon from "@/assets/images/scdd-icon.png";
import avatar from "@/assets/images/default-photo.png";
import crypto from "@/common/crypto";
import AddShoppingCart from '@/components/AddShoppingCart.vue'
import CustomerList from '@/components/CustomerList.vue'
export default {
  components: { AddShoppingCart,CustomerList },
  name: 'Home',
  data() {
    return {
      crypto,
      historyLength:window.history.length,
      avatar:avatar,//默认头像
      columnIcon:columnIcon,
      countdown:{
        timer:null,
        text:'',
        startTime:'',
        endTime:""
      },

      prodSwiper:null,//产品大图轮播
      thumbsSwiper:null,//产品缩略图轮播
      
      prodDet:{//商品详情
        loading:false,
        data:{},
        price_info:[]
      },
      detailTabActive:'det',//详情当前tab标签：详情/评价，det/eval 
      activeSpecIndex:0,//选中的规格批号索引
      prodNum:1,//购买产品选择的数量
      customPrice:0,//修改的价格

      historyList:[],

      collectLoading:false,//收藏loading
      cartLoading:false,//加入购物车loading

      activeProd:{},
      addShoppingCartShow:false,

      listEvalInfo:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[]
      },
      activedImgOfEvalShow:false,
      activedImgOfEval:'',
      bodyScrollHeight:0
    }
  },
  computed:{
    q(){
      try {
        let q=JSON.parse(crypto.decrypt(this.$route.query.q))
        return q||{}
      } catch (error) {
        return {}
      }
    },
    baseInfo(){
      return (res)=>{
        if(res){
          let arr=res.split('||')
          return arr.map(val=>{
            let item=val.split('：')
            return {key:item[0],value:item[1]}
          })
        }else{
          return []
        }
      }
    },
    //选中的规格批号
    activeSpec(){
      let val=this.prodDet.price_info
      if(val.length){
        return val[this.activeSpecIndex]||{}
      }else{
        return {}
      }
    },
    //购买数量step
    numStep(){
      let val=this.activeSpec
      if(val.pihao){
        return val.sfls==1&&val.clbz==0?0.1:parseFloat(val.clbz||1)
      }else{
        return 1
      }
    },
    //商品价格
    spPrice(){
      if(this.q.hd_id&&this.activeSpec.hd_info&&this.activeSpec.hd_info.is_step_price&&this.activeSpec.hd_info.hshsj_list.length){
        let val=this.activeSpec.hd_info.hshsj_list.find(v=>this.prodNum>=v.min&&this.prodNum<=v.max).value
        return val||this.activeSpec.hshsj
      }else{
        return this.activeSpec.hshsj
      }
    },
    prodNumDecimal(){
      let numStr = this.numStep.toString(),
    // 查找小数点
      decimalIndex = numStr.indexOf('.'),
      decimalNum=decimalIndex === -1?0:numStr.length - decimalIndex - 1
      return decimalNum
    },
    gwcQuery(){
      let q={spid:this.activeProd.spid,hd_id:this.activeProd.hd_id,pihao:this.activeProd.pihao||""}
      return q
    }
  },
  methods:{
    countdownFun(startTimestamp, endTimestamp) {
      const now = startTimestamp;
      const distance = endTimestamp - now;
    
      if (distance < 0) {
        clearInterval(this.countdownTimer);
        this.countdown.text=`活动已结束`
        // console.log("Countdown finished");
        return;
      }
    
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.countdown.text=`距活动结束还有 ${days} 天 ${hours} 小时 ${minutes} 分 ${seconds} 秒`
      // console.log(`${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`);
    },

    picSwiper(){
      this.$nextTick(()=>{
        this.prodSwiper = new Swiper('.prod-swiper', {
          thumbs: {
            swiper: {
              el: '.thumbs-swiper', //注意此处的设置方式
              freeMode: {
                enabled: true,
              },
              slidesPerView: 'auto',
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            },
            watchSlidesVisibility: true,/*避免出现bug*/
            slideThumbActiveClass: 'swiper-slide-thumb-active',
          }
        }) 
      }) 
    },
    getData(){
      if(this.q.spid){
        this.getProdInfo()
        this.getProdPici()
        this.getHistoryList()
        this.listEvalInfo={
          loading:false,
          noMore:false,
          page:1,
          pageSize:20,
          list:[]
        }
        this.getEvalList()
      }
    },
    //获取商品详情
    getProdInfo(){

      let params= {
        ...this.$store.state.basicParams,
        spid:this.q.spid,
        hd_id:this.q.hd_id||'',
        pihao:this.q.pihao||'',
        belonger_user_id:this.$store.state.currCustomer.user_id||0,
      }
      this.$instance.get('/product/get_product_info_v2',{params})
      .then(res=>{

        if(res.data.code==0){
          this.prodDet.data=res.data.data
          this.prodNum=this.prodDet.data.zhongbaozhuang||1
          this.activeSpec.pihao&&this.checkProdNum()
          this.picSwiper()
        }else{
          this.prodDet.data={}
        }
      })
      .catch(()=> {

        this.prodDet.data={}
      }); 
    },
    getProdPici(){
      clearInterval(this.countdownTimer);
     this.prodDet.loading=true
      let params= {
        ...this.$store.state.basicParams,
        spid:this.q.spid,
        hd_id:this.q.hd_id||'',
        pihao:this.q.pihao||'',
        belonger_user_id:this.$store.state.currCustomer.user_id||0,
      }
      this.$instance.get('/product/get_product_pici_info',{params})
      .then(res=>{
        this.prodDet.loading=false
        if(res.data.code==0){
          this.prodDet.price_info=res.data.data.price_info
          this.activeSpecIndex=0
          this.prodNum=this.prodDet.data.zhongbaozhuang||1
          this.customPrice=this.spPrice
          this.checkProdNum()
          //活动倒计时
         
          if(this.q.hd_id){
            this.countdown.startTime=this.activeSpec.hd_info.start_time
            this.countdown.endTime=this.activeSpec.hd_info.end_time
            this.countdown.timer = setInterval(() => {
              this.countdown.startTime+=1000
              this.countdownFun(this.countdown.startTime, this.countdown.endTime); 
            }, 1000);
          }
          
        }else{
          this.prodDet.price_info=[]
        }
      })
      .catch(()=> {
        this.prodDet.loading=false
        this.prodDet.price_info=[]
      }); 
    },
    //设置购买数量
    changeNum(type){
          if(type=='reduce'){
            this.prodNum-=this.numStep
          }else if(type=='add'){
            this.prodNum+=this.numStep
          }
          this.prodNum=parseFloat(this.prodNum.toFixed(this.prodNumDecimal))
          this.checkProdNum()
    },
    checkProdNum(){
      this.prodNum=parseFloat(this.prodNum)
      if(isNaN(this.prodNum)){
        this.prodNum=this.numStep
      }
      else if(this.prodNum>=this.activeSpec.pcjcshl||(this.activeSpec.pcjcshl==0&&this.prodNum<this.activeSpec.pcjcshl)){
        this.prodNum=this.activeSpec.pcjcshl
      }
      else if(this.prodNum<=this.numStep){
        this.prodNum=this.numStep
      }
      else if(parseFloat((this.prodNum/this.numStep).toFixed(this.prodNumDecimal)).toString().indexOf('.')>=0){
        this.prodNum=this.prodNum-(this.prodNum%this.numStep*Math.pow(10, this.prodNumDecimal||1))/Math.pow(10, this.prodNumDecimal||1)
        this.prodNum=parseFloat(this.prodNum.toFixed(this.prodNumDecimal))
      }
      this.customPrice=this.spPrice
    },
    changeSpec(index){
      this.activeSpecIndex=index;
      this.checkProdNum()
      this.customPrice=this.spPrice
    },
    checkCustomPrice(){
      if(isNaN(this.customPrice)){
        this.customPrice=this.spPrice
      }
      else if(this.customPrice>=this.activeSpec.high_price){
        this.customPrice=this.activeSpec.high_price
      }
      else if(this.customPrice<=this.activeSpec.low_price){
        this.customPrice=this.activeSpec.low_price
      }
    },
    //加入购物车
    addCart(){
      if(!this.activeSpec.can_sale){
        let phoneList=this.activeSpec.sale_phone.split(';'),
            phoneStr=''
        for(let item of phoneList){
          phoneStr+=`<div>${item}</div>`
        }

        this.$alert(`<div>${this.activeSpec.memo}，业务咨询电话：</div>${phoneStr}`, '提示', {
          customClass:"custom-msgbox",
          dangerouslyUseHTMLString:true,
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {})
        return false
      }
      this.cartLoading=true
      let params= {
        ...this.$store.state.basicParams,
        spid:this.q.spid,
        hd_id:this.q.hd_id||'',
        pihao: this.activeSpec.pihao,
        sp_buy_sl: this.prodNum,
        belonger_user_id:this.$store.state.currCustomer.user_id||0,
        sp_buy_jiage:this.activeSpec.low_price!=this.activeSpec.high_price?this.customPrice:0
      }
      this.$instance.post('/cart/add_product',this.$qs.stringify(params))
      .then(res=>{
        this.cartLoading=false
        if(res.data.code==0){
          this.$store.dispatch('gwcNum')
          this.$message({message: '商品成功加入购物车',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
      .catch(()=> {
        this.cartLoading=false
      }); 
    },
    //购买
    submit(){
      if(!this.activeSpec.pihao){
         this.$message({message: '请选择要购买的商品',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
         return false;
      }
      if(!this.activeSpec.can_sale){
        let phoneList=this.activeSpec.sale_phone.split(';'),
            phoneStr=''
        for(let item of phoneList){
          phoneStr+=`<div>${item}</div>`
        }

        this.$alert(`<div>${this.activeSpec.memo}，业务咨询电话：</div>${phoneStr}`, '提示', {
          customClass:"custom-msgbox",
          dangerouslyUseHTMLString:true,
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {})
        return false
      }
      let params= {
        selected_products:[{"spid": this.prodDet.data.spid,"pihao": this.activeSpec.pihao, "hd_id": this.prodDet.data.hd_id,"sp_buy_sl": this.prodNum,"sp_buy_jiage": this.customPrice}],
        belonger_user_id:this.$store.state.currCustomer.user_id||0,
        settlement_type:0
      }
      try{
        sessionStorage.setItem('submitOrderParams',JSON.stringify(params))
        console.log(params)
      }catch{
        //
      }
      this.$store.commit('submitOrderParams',params)
      this.$router.push({
        path:'/submitOrder',
      })

    },
    //收藏
    saveCollect(){
      this.collectLoading=true
      let params= {
        ...this.$store.state.basicParams,
        spid:this.q.spid,
      }
      this.$instance.post('/collect/save_collect',this.$qs.stringify(params))
      .then(res=>{
        this.collectLoading=false
        if(res.data.code==0){
          this.$set(this.prodDet.data,'collect_state',1)
        }
      })
      .catch(()=> {
        this.collectLoading=false
      }); 
    },
    //取消收藏
    cancelCollect(){
      this.collectLoading=true
      let params= {
        ...this.$store.state.basicParams,
        spid:this.q.spid
      }
      this.$instance.post('/collect/cancel_collect',this.$qs.stringify(params))
      .then(res=>{
        this.collectLoading=false
        if(res.data.code==0){
          this.$set(this.prodDet.data,'collect_state',0)
        }
      })
      .catch(()=> {
        this.collectLoading=false
      }); 
    },
    //获取浏览历史列表
    getHistoryList(){
      let params= {
        ...this.$store.state.basicParams,
        page:1,
        page_size:5,
      }
      this.$instance.get('/product/get_user_product_view_history',{params})
      .then(res=>{
        if(res.data.code==0){
          this.historyList=res.data.data.list
        }
      })
      .catch(()=> {
      }); 
    },
    //历史浏览加入购物车
    addShoppingCart(item){
      this.activeProd=item
      this.addShoppingCartShow=true
    },
    //获取列表
    getEvalList(){
      this.listEvalInfo.loading=true
      let params= {
        ...this.$store.state.basicParams,
        spid:this.q.spid,
        page:this.listEvalInfo.page,
        page_size:this.listEvalInfo.pageSize,
      }
      this.$instance.get('/product/get_product_pj_list',{params})
      .then(res=>{
        this.listEvalInfo.loading=false
        if(res.data.code==0){
          this.listEvalInfo.list=this.listEvalInfo.list.concat(res.data.data.list)
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          //是否全部加载完成
          if(res.data.data.list.length<this.listEvalInfo.pageSize){
            this.listEvalInfo.noMore=true
          }else{this.listEvalInfo.page+=1}
          //如果有更多增加滚动加载侦听
          if(this.listEvalInfo.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.listEvalInfo.loading=false
      }); 
    },
    //加载更多
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.main,
        allow:!this.listEvalInfo.loading&&!this.listEvalInfo.noMore&&this.detailTabActive=='eval',
        offset:0,
        callBack:this.getEvalList,
      })
    },
  },
  created(){
    this.$store.commit('submitOrderParams',null)
    try{sessionStorage.removeItem('submitOrderParams')}catch{
      //
    }
    this.getData()
  },
  mounted(){

  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {
    "$route.query.q":{
      handler(){
        this.getData()
      },
      deep:true
    },
  '$store.state.currCustomer.user_id'(){
      this.getData()
  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.prod-swiper {
    height: 350px;
    border:#f1f1f1 solid 1px;
    .pic{
      width: 100%;
      height: 350px;
    }
}  
.thumbs-swiper {
    height: 80px;
    padding:10px 0;
    border:#f1f1f1 solid 1px;
    .swiper-slide{
      width: 80px;
      margin: 0 20px;
      cursor: pointer;
    }
    .pic{
      width: 80px;
      height: 80px;
    }
}  
.swiper-slide-thumb-active{
  border:#fe5e12 solid 2px
}
.detail-price{
  background:#ffefe9 url("../../assets/images/tm.png") right top no-repeat;
  background-size: auto 100px;
  padding: 20px;
  border-radius: 10px;
}
.specifications{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  div{
    padding: 5px 10px;
    border:#d3d3d3 solid 2px;
    margin:5px;
    position: relative;
    cursor: pointer;
  }
  div.active{
    border: #fe5e12 solid 2px;
    background: #fff4ef;
    color:#fe5e12
  }
  div.active::before{
    content: '';
    position: absolute;
    border-right: #fe5e12 solid 8px;
    border-bottom: #fe5e12 solid 8px;
    border-left:transparent solid 8px;
    border-top: transparent solid 8px;
    height: 0;
    width: 0;
    bottom:0;
    right: 0;
    color: #fff;
  }
  div.active::after{
    content: '✔';
    font-size: 10px;
    position: absolute;
    bottom:0;
    right: 0;
    line-height: 1em;
    color: #fff;
  }
}
.details{
  font-size:14px;
  line-height: 1.8em;
  img{
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
}
</style>
<style>
  @import '../../assets/css/product-list-item.scss';
</style>
